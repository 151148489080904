<template>
  <div class="columns is-multiline is-marginless has-padding-50">
    <div class="column is-12">
      <profile-card :user-id="userId" />
    </div>

    <div v-if="!isResellerUser" class="column is-12">
      <collapse title="Support pin">
        <support-pin-generate
          :user-id="userId"
          :show-message="false"
          :focusable="false"
        />
      </collapse>
    </div>

    <div v-if="!isResellerUser" class="column is-12">
      <collapse title="Staff controls">
        <user-staff-controls :user-id="userId" />
      </collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminTaskAside",
  components: {
    "profile-card": () => import("@shared/account/_profileCard"),
    "user-staff-controls": () => import("@shared/user/_userStaffControls"),
    "support-pin-generate": () => import("@shared/user/_supportPinGenerate")
  },
  data() {
    return {
      userId: this.$route.params.userId
    };
  },
  computed: {
    isResellerUser() {
      return this.$store.getters["user/isResellerUser"](this.userId);
    }
  }
};
</script>
